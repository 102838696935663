<template>
  <Modal
    title="查看派发单"
    v-model="showForm"
    :mask-closable="false"
    width="1000px"
  >
    <div class="divider">派发信息</div>
    <Form ref="viewForm" label-colon :label-width="100">
      <Row>
        <Col span="12">
          <FormItem label="派发单号" prop="orderNumber">
            {{ viewForm.orderNumber }}
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="资产数量" prop="quantity">
            {{ viewForm.quantity }}
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="12">
          <FormItem label="派发时间" prop="operatorTime">
            {{ this.$moment(viewForm.operatorTime).format('YYYY-MM-DD') }}
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="审批状态" prop="approvalStatus">
            <div
              :style="{
                color:
                  viewForm.approvalStatus === 0
                    ? '#ff9900'
                    : viewForm.approvalStatus === 1
                    ? '#ed4014'
                    : '#19be6b',
              }"
            >
              {{
                viewForm.approvalStatus === 0
                  ? '审批中'
                  : viewForm.approvalStatus === 1
                  ? '驳回'
                  : '通过'
              }}
            </div>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="12">
          <FormItem label="使用人" prop="userName">
            {{ viewForm.userName }}
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="使用部门/车间" prop="userDeptName">
            {{ viewForm.userDeptName }}
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="12">
          <FormItem label="派发处理人" prop="operatorName">
            {{ viewForm.operatorName }}
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="派发单状态" prop="status">
            <div
              :style="{
                color:
                  viewForm.status === 0
                    ? '#ff9900'
                    : viewForm.status === 1
                    ? '#ed4014'
                    : viewForm.status === 2
                    ? '#19be6b'
                    : '',
              }"
            >
              {{
                viewForm.status === 0
                  ? '派发中'
                  : viewForm.status === 1
                  ? '已撤回'
                  : viewForm.status === 2
                  ? '已领取'
                  : ''
              }}
            </div>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="12">
          <FormItem label="创建人" prop="createName">
            {{ viewForm.createName }}
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="创建时间" prop="createTime">
            {{
              this.$moment(viewForm.createTime).format('YYYY-MM-DD HH:mm:ss')
            }}
          </FormItem>
        </Col>
        <Col span="12" v-if="viewForm.recallTime !== null && viewForm.status === 1">
          <FormItem label="撤回时间" prop="recallTime">
            {{
              this.$moment(viewForm.recallTime).format('YYYY-MM-DD HH:mm:ss')
            }}
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <FormItem label="备注" prop="memo">
            {{ viewForm.memo }}
          </FormItem>
        </Col>
      </Row>
    </Form>
    <div v-if="approverList.length !== 0">
      <div class="divider">审核信息</div>
      <Form class="plain-form" label-colon :label-width="90">
          <Steps direction="vertical" :current="approverList.length-1">
              <Step :title="item.psName + '：' + item.approverName" v-for="item in approverList" :key="item.id">
                  <div slot="content">
                      审核状态：
                          <span :style="{color: item.approvalStatus === 0 ? '#ff9900' : (item.approvalStatus === 1 ? '#ed4014' : '#19be6b')}">
                              {{item.approvalStatus === 0 ? '审核中' : (item.approvalStatus === 1 ? '驳回' : '通过')}}
                          </span>，
                      审核时间：{{item.approvedTime}}，
                      审核意见：{{item.approvalComment ? item.approvalComment : '无'}}
                  </div>
              </Step>
          </Steps>
      </Form>
    </div>
    <div class="divider">资产信息</div>
    <div class="table">
      <div class="table-list">
        <Table stripe :columns="columns" :data="dataList"> </Table>
      </div>
    </div>
    <div slot="footer">
      <Button type="primary" @click="closeModal(false)">确定</Button>
    </div>
  </Modal>
</template>

<script>
  import { reqGetInfoByDistributeId } from '../../../../api/asset-api'

  export default {
    name: 'viewDistribute',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      distributeId: '',
    },
    data() {
      return {
        showForm: false,
        viewForm: {
          orderNumber: '',
          userName: '',
          userDeptName: '',
          operatorTime: '',
          operatorName: '',
          memo: '',
          status: '',
          approvalStatus: '',
          quantity: 0,
          createName: '',
          createTime: '',
          recallTime: '',
        },
        columns: [
          {
            title: '资产编码',
            key: 'encoding',
            tooltip: 'ture',
            minWidth: 170,
          },
          {
            title: '资产名称',
            key: 'assetName',
            tooltip: 'ture',
            width: 150,
          },
          {
            title: '资产分类',
            key: 'classifyName',
            tooltip: 'ture',
            minWidth: 130,
          },
          {
            title: '品牌',
            key: 'brand',
            tooltip: 'ture',
            width: 100,
          },
          {
            title: '型号',
            key: 'model',
            width: 150,
          },
          {
            title: 'SN码',
            key: 'snCode',
            tooltip: 'ture',
            width: 100,
          },
          {
            title: '所在位置',
            key: 'locationName',
            tooltip: 'ture',
            width: 150,
          },
        ],
        dataList: [],
        approverList: [],
      }
    },
    watch: {
      value(val) {
        this.showForm = val
      },
      showForm(val) {
        if (val) {
          reqGetInfoByDistributeId({ id: this.distributeId }).then((res) => {
            this.dataList = res.data.data[0].assetList
            this.viewForm = res.data.data[0]
            this.approverList = res.data.data[0].approverList
          })
        } else {
          // 反之则关闭页面
          this.closeModal(val)
        }
      },
    },
    methods: {
      closeModal(val) {
        this.$emit('input', val)
      },
    },
    mounted() {},
  }
</script>